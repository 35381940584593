
import baseConstants from '~/store/base/-constants'

export default {
  name: 'MoleculeMenuTriggerUniverse',
  computed: {
    isActive() {
      return this.$store.state[baseConstants.namespace].sidebarOpen
    }
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_SIDEBAR))
    }
  }
}
