
import universeConstants from '~/store/universe/-constants'

export default {
  name: 'AtomLoaderLoadingBar',
  components: {},
  props: {
    parent: {
      required: false,
      type: String,
      default: 'building'
    },
    type: {
      required: false,
      type: String,
      default: 'building'
    }
  },
  data() {
    return {
      percentage: 0,
      seconds: 30,
      maxPercentage: 90,
      interval: 0,
      closeInterval: 0,
      closeTimeRemaining: 0,
      startedCountdown: false
    }
  },
  computed: {},
  mounted() {
    if (this.parent === 'universe') {
      // const percentageChange = Math.floor(Math.random() * Math.round(this.maxPercentage / this.seconds) + 1);
      // let percentage = this.percentage;
      // percentage += percentageChange;
      // if (percentage > this.maxPercentage) {
      //   this.percentage = 99;
      //   this.interval = 0;
      //   return;
      // }
      // this.percentage = percentage;

      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.CHANGE_LOADER_STATE),
        { isLoading: false }
      )
      // console.log('LOADER STATE', this.$store.state.universe.universe.loader.isLoaderActive)

      // this.$store.subscribe((payload, state) => {
      //   console.log(payload, state)
      // });

      // setTimeout(  () => {
      //   this.$store.state.universe.universe.loader.isLoaderActive = false;
      //   console.log("LOADER STATE", this.$store.state.universe.universe.loader.isLoaderActive)
      // }, 2000);
    }
    if (this.parent === 'building') {
      // console.log('building loader')
      this.interval = setInterval(() => {
        const percentageChange = Math.floor(
          Math.random() * Math.round(this.maxPercentage / this.seconds) + 1
        )
        let percentage = this.percentage
        percentage += percentageChange
        if (percentage > this.maxPercentage) {
          this.percentage = 99
          clearInterval(this.interval)
          this.interval = 0
          // this.startCloseCountdown();
          return
        }
        this.percentage = percentage
      }, 1000)
      this.$store.subscribe((payload, state) => {
        if (state.building.finishedLoadingAssets && this.percentage < 100) {
          clearInterval(this.interval)
          this.interval = 0
          this.percentage = 100
          this.startCloseCountdown()
        }
      })
    }
  },
  beforeDestroy() {
    if (this.parent === 'building') {
      clearInterval(this.interval)
      clearInterval(this.closeInterval)
    }
  },
  methods: {
    closeLoadingScreen() {
      this.$store.dispatch('building/changeLoadingScreenStatus', false)
      clearInterval(this.interval)
      clearInterval(this.closeInterval)
    },
    startCloseCountdown() {
      this.closeInterval = setInterval(() => {
        this.closeLoadingScreen()
        clearInterval(this.closeInterval)
        this.closeInterval = 0
      }, 1000)
    }
  }
}
