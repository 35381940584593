import BaseApiModule from './BaseApiModule'

class SpaceApiModule extends BaseApiModule {
  readonly controller: string
  readonly v2controller: string

  constructor(url: string) {
    super(url)
    this.controller = 'space'
    this.v2controller = 'v2/space'
  }

  details(spaceId: number): string {
    const methodUrl = 'public'
    return `${this.baseUrl}/${this.v2controller}/${methodUrl}/${spaceId}`
  }
}

export default SpaceApiModule
