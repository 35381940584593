
export default {
  name: 'AtomLogo',
  props: {
    white: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    resourceUrl() {
      let base = `${this.$store.getters['base/cdnBase']}/`
      if (this.white) {
        if (this.$store.getters['base/logoImageWhite'].includes('://')) base = ''
        return `${base}${this.$store.getters['base/logoImageWhite']}`
      }
      if (this.$store.getters['base/logoImage'].includes('://')) base = ''
      return `${base}${this.$store.getters['base/logoImage']}`
    }
  }
}
