export class AnalyticsLayerHelper {
  isServer: boolean = typeof window === 'undefined'
  initInterval: any = 0
  analyticsLayer: any = {}
  constructor() {
    if (!this.isServer && !this.initInterval) {
      this.initInterval = setInterval(() => {
        this.enforceLayerCheck(() => {
          console.log(
            `[ANALYTICS] Bright Analytics connected with code <${window.analyticsLayer.identifier}>.`
          )
          this.analyticsLayer = window.analyticsLayer
        })
        if (this.checkLayerAvailability()) {
          clearInterval(this.initInterval)
        }
      }, 100)
    }
  }

  checkLayerAvailability(): boolean {
    if (this.isServer) {
      return false
    }
    return 'analyticsLayer' in window
  }

  enforceLayerCheck(func: () => void = () => {}) {
    if (this.checkLayerAvailability()) {
      func()
    }
  }

  recordEvent(eventName: string, payload: Record<string, any>) {
    this.enforceLayerCheck(() => {
      this.analyticsLayer.send({
        event: eventName,
        payload
      })
    })
  }
}

const AnalyticsLayer = new AnalyticsLayerHelper()

export default AnalyticsLayer
