
export default {
  name: 'OrganismLoader',
  props: {
    contentLoader: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    parent: {
      required: false,
      type: String,
      default: 'building'
    },
    loaderType: {
      required: false,
      type: String,
      default: 'building'
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
