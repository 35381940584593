
import baseConstants from '@/store/base/-constants'

export default {
  name: 'OrganismInfoPopup',
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].infoOpen
    }
  }
}
