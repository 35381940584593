
import baseConstants from '~/store/base/-constants'
import tutorialConstants from '@/store/tutorial/-constants'
export default {
  name: 'AtomLocaleSwitch',
  data() {
    return {
      languages: [
        {
          locale: 'ro',
          text: 'Romana'
        },
        {
          locale: 'en',
          text: 'English'
        }
      ]
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale || this.$i18n.fallbackLocale
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang
      this.$cookiz.set('language-cookie', lang)
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_LANGUAGE), lang)

      const manager = this.engine3d?.getClientManager()
      if (!manager) return
      manager.changePinsLang(lang)

      if (window.innerWidth < 768) {
        if (!this.$store.state.tutorial.LANGUAGE_CHANGER_MOBILE) {
          this.$store.dispatch(
            tutorialConstants.namespaceAction.TRIGGER_PANEL,
            'LANGUAGE_CHANGER_MOBILE'
          )
        }
      }
    }
  }
}
