import BaseApiModule from './BaseApiModule'

class AuthApiModule extends BaseApiModule {
  readonly controller: string

  constructor(url: string) {
    super(url)
    this.controller = 'auth'
  }

  doHeartbeat(): string {
    const methodUrl = 'heartbeat'
    return `${this.baseUrl}/${this.controller}/${methodUrl}`
  }

  doLogin(): string {
    const methodUrl = 'login'
    return `${this.baseUrl}/${this.controller}/${methodUrl}`
  }
}

export default AuthApiModule
