
export default {
  name: 'AtomIcon',
  props: {
    icon: {
      required: true,
      type: String
    },
    size: {
      required: false,
      type: Number,
      default: 10
    },
    color: {
      required: false,
      type: String,
      default: '#000'
    }
  }
}
