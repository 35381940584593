
import baseConstants from '../../store/base/-constants'
import tutorialConstants from '@/store/tutorial/-constants'

export default {
  name: 'MoleculeMenuTrigger',
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].sidebarOpen
    }
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_SIDEBAR))
      if (window.innerWidth < 768) {
        if (!this.$store.state.tutorial.LANGUAGE_CHANGER_MOBILE) {
          this.$store.dispatch(
            tutorialConstants.namespaceAction.TRIGGER_PANEL,
            'LANGUAGE_CHANGER_MOBILE'
          )
        }
      }
    }
  }
}
