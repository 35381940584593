
import universeConstants from '~/store/universe/-constants'

export default {
  name: 'OrganismUniverseTutorial',
  computed: {
    open() {
      return this.$store.state?.universe?.infoBox?.open || false
    }
  },
  methods: {
    cdnImage(image) {
      return this.$store.getters['base/cdnBase'] + image
    },
    handleInfoTrigger() {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_INFO_BOX),
        {
          open: !this.open
        }
      )
    }
  }
}
