import BaseApiModule from './BaseApiModule'

class GuidedTourApiModule extends BaseApiModule {
  readonly controller: string
  readonly contentBlockController: string

  constructor(url: string) {
    super(url)
    this.controller = 'v2/admin/virtual_tour'
    this.contentBlockController = 'v2/admin/virtual_tour_location_content_block'
  }

  details(uuid: string): string {
    const methodUrl = 'public'
    return `${this.baseUrl}/${this.controller}/${methodUrl}/${uuid}`
  }

  locationContentBlocks(id: number): string {
    const methodUrl = 'public'
    const filters = {
      virtual_tour_location_id: id
    }
    const queryStringObject = {
      filters: JSON.stringify(filters),
      per_page: -1,
      order_by: 'sequence_order',
      order: 'ASC'
    }
    const queryString = new URLSearchParams(queryStringObject as any).toString()
    return `${this.baseUrl}/${this.contentBlockController}/${methodUrl}?${queryString}`
  }
}

export default GuidedTourApiModule
