
import tutorialConstants from '~/store/tutorial/-constants'

export default {
  name: 'OrganismModalLeavePage',
  data() {
    return {
      isLeaving: false,
      isScrolledMoreThanHalf: false
    }
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    visibleHubspotPopup() {
      if (!this.generalConfig?.hubspotPopup) return false
      return this.generalConfig?.hubspotPopup?.visible
    },
    hubspotFormId() {
      if (!this.generalConfig?.hubspotPopup) return ''
      return this.generalConfig?.hubspotPopup?.formId
    },
    hubspotPortalId() {
      if (!this.generalConfig.hubspotPopup) return ''
      return this.generalConfig?.hubspotPopup?.portalId
    }
  },
  watch: {
    isScrolledMoreThanHalf: function (hasScrolled) {
      if (hasScrolled && process.client) {
        document.addEventListener('mouseleave', this.mouseLeftViewport)
      }
    }
  },
  beforeMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: this.hubspotPortalId,
          formId: this.hubspotFormId,
          target: '#hubspotForm'
        })

        window.addEventListener('message', (event) => {
          if (
            event.data.type === 'hsFormCallback' &&
            event.data.eventName === 'onFormSubmitted' &&
            window &&
            'analyticsLayer' in window
          ) {
            window.analyticsLayer.send({
              event: 'on_leave_hubspot_form_submitted',
              payload: event.data
            })
          }
        })
      }
    })
  },
  mounted() {
    // Ensure this code runs only in client-side
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      window.addEventListener('scroll', this.handleScroll50PercentTrigger)
    }
  },
  beforeDestroy() {
    // Ensure this code runs only in client-side
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll50PercentTrigger)
      document.removeEventListener('mouseleave', this.mouseLeftViewport)
    }
  },
  methods: {
    handleScroll50PercentTrigger() {
      // Calculate the total height of the document
      const documentHeight = document.documentElement.scrollHeight
      // Calculate the height of the viewport
      const viewportHeight = window.innerHeight
      // Calculate the current scroll position from the top
      const scrollTop = window.scrollY || document.documentElement.scrollTop

      // Check if the user has scrolled more than 50% of the document height
      if (scrollTop > (documentHeight - viewportHeight) / 2) {
        this.isScrolledMoreThanHalf = true
      }
    },
    handleClose() {
      this.$store.dispatch(tutorialConstants.namespaceAction.TRIGGER_PANEL, 'LEAVE_PAGE')
      this.isLeaving = false
    },
    mouseLeftViewport() {
      this.isLeaving = true
    },
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
