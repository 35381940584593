import BaseApiModule from './BaseApiModule'

class LandlordApiModule extends BaseApiModule {
  readonly controller: string

  constructor(url: string) {
    super(url)
    this.controller = 'client'
  }

  details(): string {
    const methodUrl = 'details'
    return `${this.baseUrl}/${this.controller}/${methodUrl}`
  }

  availability(projectId: number): string {
    const methodUrl = 'availability'
    return `${this.baseUrl}/${this.controller}/${methodUrl}/${projectId}`
  }

  contact(): string {
    const methodUrl = 'deal'
    return `${this.baseUrl}/${methodUrl}`
  }

  requestOffer(): string {
    const methodUrl = 'deal'
    return `${this.baseUrl}/${methodUrl}`
  }

  downloadBrochure(): string {
    const methodUrl = 'brochure'
    return `${this.baseUrl}/${this.controller}/${methodUrl}`
  }

  getOffer(offerUUID: number): string {
    const methodUrl = 'offer'
    return `${this.baseUrl}/${this.controller}/${methodUrl}/${offerUUID}`
  }
}

export default LandlordApiModule
