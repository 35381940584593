import { render, staticRenderFns } from "./OrganismMenuBarProject.vue?vue&type=template&id=d62249a4&"
import script from "./OrganismMenuBarProject.vue?vue&type=script&lang=js&"
export * from "./OrganismMenuBarProject.vue?vue&type=script&lang=js&"
import style0 from "./OrganismMenuBarProject.vue?vue&type=style&index=0&id=d62249a4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,MoleculesMoleculeMenuTrigger: require('/app/components/molecules/MoleculeMenuTrigger.vue').default,AtomsCommonAtomLogo: require('/app/components/atoms/common/AtomLogo.vue').default,AtomsCommonAtomLocaleSwitch: require('/app/components/atoms/common/AtomLocaleSwitch.vue').default})
